<template>
<v-row>
    <v-col cols="12" md="12">
        <s-crud :singleRow="false" no-full title="Activos Fijos" height="350px" :filter="filter" :config="this.config" @save="save($event)" add edit remove @rowSelected="rowSelected($event)" searchInput>
            <template v-slot:filter>
                    <v-container>
                        <v-container>
                            <v-row justify="center">
                                <v-col lg="4" md="4" cols="12" class="s-col-form">
                                    <s-select-definition clearable label="Estados" v-model="filter.TypeActive" :def="1148" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-container>
                </template>
            <template slot-scope="props">
                <v-container v-if="props.item != null" class="pb-0">
                    <v-row justify="center">
                        <v-col cols="6" lg="6" md="6" class="s-col-form">
                            <s-select-definition v-model="props.item.TypeActive" :def="1148" label="Tipo activo"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="6" md="6" class="s-col-form">
                            <s-text v-model="props.item.FasCodeActive" label="Cod. activo." />
                        </v-col>
                        <v-col cols="12" lg="12" md="12" class="s-col-form">
                            <s-textarea v-model="props.item.FasDescription" label="Descripción" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <!-- <template v-slot:Print="{row}">
                <v-btn x-small elevation="0" fab color="" @click="viewQR(row)"><i style="font-size: 16px" class="fas fa-qrcode"></i></v-btn>

            </template> -->
        </s-crud>
    </v-col>

    <v-col cols="12" v-for="item in itemsAll" :key="item.FasCodeActive" style="display:none">
                <qr-code :size="50" :text="item.FasCodeActive" error-level="H">
                </qr-code>

                <!-- <barcode v-bind:value="itemQR.FasCodeActive">
                    Show this if the rendering fails.
                </barcode> -->
            </v-col>
            <v-col cols="12">
                <v-btn @click="printQR()" width="100%" small color="info" elevation="0">Imprimir</v-btn>
            </v-col>

    <v-dialog v-model="openDialog" width="280px" v-if="openDialog" transition="dialog-bottom-transition" >
        <v-card>
        <v-row justify="center" style="margin:auto">
            
        </v-row>
       </v-card>
        

    </v-dialog>

</v-row>
</template>

<script>
import _sFixedActive from "@/services/Inventory/InvFixedAssets";
import qrCode from "vue-qr-generator";

import Barcode from 'vue-barcode';

export default {
    components: {
        qrCode,
        Barcode
    },
    data: () => ({
        filter:{
            TypeActive: 1
        },
        config: {
            model: {
                FasID: "ID",
                TypeActiveName: "int",
                FasCodeActive: "string",
                FasDescription: "string",
                Print: ""

            },

            service: _sFixedActive,
            headers: [
                /* {
                    text: "Print QR",
                    value: "Print",
                    sorteable: false,
                    width: 5
                }, */
                {
                    text: "ID",
                    value: "FasID",
                    sorteable: false,
                    width: 10
                },
                {
                    text: "Cod. Activo Fijo",
                    value: "FasCodeActive",
                    sorteable: false,
                    width: 50
                },
                {
                    text: "Tipo Activo",
                    value: "TypeActiveName",
                    sorteable: false,
                    width: 100
                },
                
                {
                    text: "Descripción",
                    value: "FasDescription",
                    sorteable: false,
                    width: 250
                },

            ],
        },
        itemQR: {
            FasCodeActive: "",
            FasDescription: ""
        },
        openDialog: false,
        itemsAll: []
    }),
    created() {

    },
    methods: {
        rowSelected(items) {
            
            
            items.forEach((element, index) => {
                element.Position = index
            });

            this.itemsAll = items

            //this.itemsAll.sort(Position)

            
        },

        save(item) {
            item.SecStatus = 1
            item.UsrCreateID = this.$fun.getUserID()

            if (item.TypeActive == null) {
                this.$fun.alert("Seleccione tipo de Activo Fijo", "warning")
                return
            }

            if (item.FasCodeActive.length == "" || item.FasCodeActive == null || item.FasCodeActive.length == 0) {
                this.$fun.alert("Ingrese Codigo de Activo Fijo", "warning")
                return
            }

            if (item.FasDescription.length == "" || item.FasDescription == null || item.FasDescription.length == 0) {
                this.$fun.alert("Ingrese descripcion", "warning")
                return
            }
            item.save()
        },

        viewQR(item) {
            this.itemQR = item
            this.openDialog = true
        },

        printQR() {

				var imgAll = document.querySelectorAll("img");
                var i=0

                //console.log("seleccionado",this.itemsAll)

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-top: 0cm; {} </style></head><body onload='step1()'></body></html>",
				);
                    imgAll.forEach((element, index) => {
                        console.log("index", index)
                        pwa.document.write("<table style='margin-bottom: 800px; margin-left: 90px; margin-top: -12px' border='0'><tr><td colspan='2' style='text-align: center; font-size: 9px; font-family: Calibri'><b>INVENTARIO 08/2022 </b></td></tr>")
                        pwa.document.write(
                            "<tr><td><img height='50px' width='100px' alt='Scan me!' style='display: block;'  src='" +
                                'https://imperu.com.pe/Logos/Imp_inventario.png' +
                                "' /></td><td style='text-align: center'><div style='margin: 0px 0px 0px 0px;'><img alt='Scan me!' style='display: block;'  src='" +
                                element.currentSrc +
                                "' /></div></td></tr>",
                        );
                        pwa.document.write("<tr><td></td><td style='text-align: center; font-size: 10px; margin-top:-50px'><b>" + this.itemsAll.filter(x => x.Position == index)[0].FasCodeActive + "</b></td></tr>")
                        pwa.document.write("</table>")
                        //console.log("Codigo: ", this.itemsAll.filter(x => x.Position == index)[0].FasCodeActive)
                        /* i = i +1 */
                       /*  console.log("contando",i) */
                    });

                pwa.document.write("")
                    /* this.itemsAll.forEach(element => {
                        pwa.document.write("<table border='0'><tr><td colspan='2' style='text-align: center; font-size: 20px; font-family: Calibri'><b>INVENTARIO 08/2022 </b></td></tr>")
                        pwa.document.write("<tr><td></td></tr>")
                        

                        pwa.document.write("<tr><td></td><td style='text-align: center; font-size: 16px'><b>" + element.FasCodeActive + "</b></td></tr>")

                        

                        pwa.document.write("</table>")
                    }); */
			        

				pwa.document.close();

				
			},
    },

};
</script>
